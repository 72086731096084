import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  TextField,
  Grid,
  Button,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { useQuery } from "react-query";
import SizedBox from "../SizedBox";
import CurrencyTextField from "../CurrencyTextField";
import API from "../../services/api";
import { Formik } from "formik";
import { getInteger } from "../../Common/helperMethods";
import { useToastDisplay } from "../../Common/useToastDisplay";

const Summary_API_URL = process.env.REACT_APP_SUMMARY_CONTROLLER;

function Summary({ role, client }) {
  const [isDownloading, setIsDownloading] = useState(false);
  const showToast = useToastDisplay();
  const isClient = role === "Client";
  const { id } = useParams();
  const selectedQuarter = parseInt(client?.qtrType.replace(/[^0-9]/g, "") || 0);
  const { data, isLoading } = useQuery(
    ["GetSummary", id],
    async () => {
      return API.get(Summary_API_URL + `GetSummary/${id}`)
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );

  const isDisabled = client?.isDisabled || false;
  const taxRate =
    selectedQuarter === 1
      ? 15
      : selectedQuarter === 2
      ? 45
      : selectedQuarter === 3
      ? 75
      : selectedQuarter === 4
      ? 100
      : 0;
  const summaryData = data
    ? data
    : {
        interest: 0,
        dividend: 0,
        taxableRental: 0,
        capitalGains: 0,
        salaryAdjustment: 0,
        otherIncome: 0,
        seperateDeduction: "",
        totalIncome: 0,
        taxOnCG: 0,
        totalTax: 0,
        tDS: 0,
        tCS: 0,
        otherTaxCredit: 0,
        balanceTax: 0,
        dueAmount: 0,
        advanceTaxPaidTillDate: 0,
        advanceTaxPaidQ1: 0,
        advanceTaxPaidQ2: 0,
        advanceTaxPaidQ3: 0,
        advanceTaxPaidQ4: 0,
        dueThisQuarter: 0,
        taxOnNormalIncome: 0,
        taxableSalary: 0,
        taxSlabRate: 0,
        residentialStatus: "",
        comment: "",
        taxQtrProfileId: id,
        taxProfileId: "00000000-0000-0000-0000-000000000000",
      };

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        size={60}
      />
    );
  }

  const calculateBalanceTax = (totalTax, tds, tcs, otherTaxCredit) => {
    return (
      getInteger(totalTax) -
      (getInteger(tds) + getInteger(tcs) + getInteger(otherTaxCredit))
    );
  };

  const calculateDueThisQuarter = (dueAmount, advanceTaxPaidTillDate) => {
    const dueThisQuarter =
      getInteger(dueAmount) - getInteger(advanceTaxPaidTillDate);
    return Math.max(dueThisQuarter, 0);
  };

  const isDangerZoneDisplay = (
    totalNormalIncome,
    taxableSalary,
    indianDividendIncome
  ) => {
    const amt =
      getInteger(totalNormalIncome) +
      getInteger(taxableSalary) -
      getInteger(indianDividendIncome);

    if (amt >= 4500000 && amt <= 5400000) return true;
    else if (amt >= 9400000 && amt <= 10800000) return true;
    else if (amt >= 19000000 && amt <= 22000000) return true;
    else if (amt >= 48000000 && amt <= 55000000) return true;
    else return false;
  };

  const handleDownload = async () => {
    setIsDownloading(true);

    //Make a request to the API endpoint
    await API.get(Summary_API_URL + `DownloadSummary?qtrId=${id}`, {
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Summary.pdf`);
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);

      setIsDownloading(false);
    });
  };

  return (
    <div>
      <Formik
        initialValues={summaryData}
        onSubmit={async (values, actions) => {
          API.post(Summary_API_URL + "AddSummaryDetails", {
            ...values,
            advanceTaxPaidTillDate: getInteger(values.advanceTaxPaidTillDate),
            advanceTaxPaidQ1: getInteger(values.advanceTaxPaidQ1),
            advanceTaxPaidQ2: getInteger(values.advanceTaxPaidQ2),
            advanceTaxPaidQ3: getInteger(values.advanceTaxPaidQ3),
            advanceTaxPaidQ4: getInteger(values.advanceTaxPaidQ4),
            balanceTax: getInteger(values.balanceTax),
            capitalGains: getInteger(values.capitalGains),
            dividends: getInteger(values.dividends),
            dueAmount: getInteger(values.dueAmount),
            dueThisQuarter: getInteger(values.dueThisQuarter),
            interest: getInteger(values.interest),
            otherIncome: getInteger(values.otherIncome),
            otherTaxCredit: getInteger(values.otherTaxCredit),
            salaryAdjustment: getInteger(values.salaryAdjustment),
            seperateDeduction: getInteger(values.seperateDeduction),
            taxableRental: getInteger(values.taxableRental),
            taxOnCG: getInteger(values.taxOnCG),
            taxOnNormalIncome: getInteger(values.taxOnNormalIncome),
            tcs: getInteger(values.tcs),
            tds: getInteger(values.tds),
            totalIncome: getInteger(values.totalIncome),
            totalTax: getInteger(values.totalTax),
          })
            .then((response) => {
              showToast({
                message: "Summary submitted successfully!",
                severity: "success",
              });
            })
            .catch((err) => {
              showToast({
                message: "Error occured while saving",
                severity: "error",
              });
            });
        }}
      >
        {(formikProps) => (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8} md={8}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    flex: 1,
                  }}
                >
                  <h3 style={{ marginTop: 0 }}>
                    Residential Status : {data.residentialStatus}
                  </h3>
                  <h3 style={{ marginLeft: 20, marginTop: 0 }}>
                    Citizenship : {data.citizenship}
                  </h3>
                  {!isClient && data.isSeniorCitizen && data.isSeniorCitizen === true && (
                    <h3 style={{ marginLeft: 20, marginTop: 0 }}>
                      Senior citizen : Yes
                    </h3>
                  )}
                  {!isClient && data.dataPullFromItdOn && data.dataPullFromItdOn !== "" && (
                    <h3 style={{ marginLeft: 20, marginTop: 0 }}>
                      AIS : {data.dataPullFromItdOn}
                    </h3>
                  )}
                  {!isClient &&
                    isDangerZoneDisplay(
                      data.totalIncome,
                      data.taxableSalary,
                      data.indianDiv
                    ) && (
                      <h3
                        style={{ color: "red", marginLeft: 20, marginTop: 0 }}
                      >
                        Important !!! 104% Danger Zone - ₹{" "}
                        {(
                          getInteger(data.totalIncome) +
                          getInteger(data.taxableSalary) -
                          getInteger(data.indianDiv)
                        ).toLocaleString("en-US")}
                      </h3>
                    )}
                </div>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                {!isClient && (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ textAlign: "right" }}
                    onClick={handleDownload}
                    disabled={isDownloading}
                  >
                    {isDownloading ? "Downloading..." : "Download Summary"}
                  </Button>
                )}
              </Grid>
            </Grid>

            <SizedBox height={10} width={10}></SizedBox>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="interest"
                    label="Interest"
                    fullWidth
                    maximumValue="1000000000000"
                    value={formikProps.values.interest}
                    disabled={true}
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="dividend"
                    label="Dividends"
                    maximumValue="1000000000000"
                    fullWidth
                    value={formikProps.values.dividend}
                    disabled={true}
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="taxableRental"
                    label="Taxable rental"
                    fullWidth
                    value={formikProps.values.taxableRental}
                    minimumValue="-99999999"
                    maximumValue="1000000000000"
                    disabled={true}
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="capitalGains"
                    label="Capital Gains"
                    fullWidth
                    maximumValue="1000000000000"
                    value={formikProps.values.capitalGains}
                    disabled={true}
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="salaryAdjustment"
                    label="Salary adjustment"
                    fullWidth
                    maximumValue="1000000000000"
                    value={formikProps.values.salaryAdjustment}
                    disabled={true}
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="otherIncome"
                    label="Other Income"
                    fullWidth
                    maximumValue="1000000000000"
                    value={formikProps.values.otherIncome}
                    disabled={true}
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="seperateDeduction"
                    label="Separate deductions"
                    fullWidth
                    maximumValue="1000000000000"
                    value={formikProps.values.seperateDeduction}
                    disabled={true}
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="exemption"
                    label="Exemption"
                    fullWidth
                    maximumValue="1000000000000"
                    value={formikProps.values.exemption}
                    disabled={true}
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="totalIncome"
                    label="Total of normal income"
                    fullWidth
                    value={formikProps.values.totalIncome}
                    maximumValue="1000000000000"
                    disabled={true}
                    minimumValue="-99999999"
                  />
                </Grid>

                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="taxOnNormalIncome"
                    label="Tax on normal income"
                    fullWidth
                    maximumValue="1000000000000"
                    value={formikProps.values.taxOnNormalIncome}
                    disabled={true}
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="taxOnShortGain15"
                    label="Tax on Short Term Gain 15%"
                    maximumValue="1000000000000"
                    fullWidth
                    value={formikProps.values.taxOnShortTerm_15}
                    disabled={true}
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="taxOnShortTerm_Normal"
                    label="Tax on Short Term Gain Normal"
                    maximumValue="1000000000000"
                    fullWidth
                    value={formikProps.values.taxOnShortTerm_Normal}
                    disabled={true}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="TaxOnLongTerm_10"
                    label="Tax on Long Term Gain 10%"
                    fullWidth
                    value={formikProps.values.taxOnLongTerm_10}
                    maximumValue="1000000000000"
                    disabled={true}
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="TaxOnLongTerm_20"
                    label="Tax on Long Term Gain 20%"
                    fullWidth
                    value={formikProps.values.taxOnLongTerm_20}
                    maximumValue="1000000000000"
                    disabled={true}
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>

                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="totalTax"
                    label="Total Tax"
                    fullWidth
                    value={formikProps.values.totalTax}
                    maximumValue="1000000000000"
                    disabled={true}
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="tds"
                    label="TDS"
                    fullWidth
                    value={formikProps.values.tds}
                    maximumValue="1000000000000"
                    disabled={true}
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="tcs"
                    label="TCS"
                    fullWidth
                    disabled={true}
                    onChange={(e) => {
                      formikProps.setFieldValue("tcs", e.target.value);
                      const balanceTax = calculateBalanceTax(
                        formikProps.values.totalTax,
                        formikProps.values.tds,
                        e.target.value,
                        formikProps.values.otherTaxCredit
                      );
                      formikProps.setFieldValue(
                        "balanceTax",
                        getInteger(balanceTax)
                      );
                      formikProps.setFieldValue(
                        "dueAmount",
                        (getInteger(balanceTax) * taxRate) / 100
                      );
                    }}
                    onBlur={(e) => {
                      formikProps.setFieldValue("tcs", e.target.value);
                      const balanceTax = calculateBalanceTax(
                        formikProps.values.totalTax,
                        formikProps.values.tds,
                        e.target.value,
                        formikProps.values.otherTaxCredit
                      );
                      formikProps.setFieldValue(
                        "balanceTax",
                        getInteger(balanceTax)
                      );
                      formikProps.setFieldValue(
                        "dueAmount",
                        (getInteger(balanceTax) * taxRate) / 100
                      );
                    }}
                    value={formikProps.values.tcs}
                    maximumValue="1000000000000"
                    error={formikProps.touched.tcs && !!formikProps.errors.tcs}
                    helperText={
                      formikProps.touched.tcs && formikProps.errors.tcs
                    }
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="otherTaxCredit"
                    label="Other tax credits"
                    fullWidth
                    // onChange={formikProps.handleChange}
                    // onBlur={formikProps.handleBlur}
                    onChange={(e) => {
                      formikProps.setFieldValue(
                        "otherTaxCredit",
                        e.target.value
                      );
                      const balanceTax = calculateBalanceTax(
                        formikProps.values.totalTax,
                        formikProps.values.tds,
                        formikProps.values.tcs,
                        e.target.value
                      );
                      formikProps.setFieldValue(
                        "balanceTax",
                        getInteger(balanceTax)
                      );
                      formikProps.setFieldValue(
                        "dueAmount",
                        (getInteger(balanceTax) * taxRate) / 100
                      );
                    }}
                    onBlur={(e) => {
                      formikProps.setFieldValue(
                        "otherTaxCredit",
                        e.target.value
                      );
                      const balanceTax = calculateBalanceTax(
                        formikProps.values.totalTax,
                        formikProps.values.tds,
                        formikProps.values.tcs,
                        e.target.value
                      );
                      formikProps.setFieldValue(
                        "balanceTax",
                        getInteger(balanceTax)
                      );
                      formikProps.setFieldValue(
                        "dueAmount",
                        (getInteger(balanceTax) * taxRate) / 100
                      );
                    }}
                    value={formikProps.values.otherTaxCredit}
                    maximumValue="1000000000000"
                    disabled={true}
                    error={
                      formikProps.touched.otherTaxCredit &&
                      !!formikProps.errors.otherTaxCredit
                    }
                    helperText={
                      formikProps.touched.otherTaxCredit &&
                      formikProps.errors.otherTaxCredit
                    }
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="balanceTax"
                    label="Balance tax"
                    fullWidth
                    value={formikProps.values.balanceTax}
                    disabled={true}
                    maximumValue="1000000000000"
                    minimumValue="-99999999"
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="dueAmount"
                    label="Due Amount"
                    fullWidth
                    value={formikProps.values.dueAmount}
                    disabled={true}
                    maximumValue="1000000000000"
                    minimumValue="-99999999"
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="advanceTaxPaidQ1"
                    label="Advance tax paid in Q1"
                    onBlur={formikProps.handleBlur}
                    fullWidth
                    value={formikProps.values.advanceTaxPaidQ1}
                    onChange={(e) => {
                      formikProps.setFieldValue(
                        "advanceTaxPaidQ1",
                        e.target.value
                      );
                      formikProps.setFieldValue(
                        "advanceTaxPaidTillDate",
                        getInteger(e.target.value) +
                          getInteger(formikProps.values.advanceTaxPaidQ2) +
                          getInteger(formikProps.values.advanceTaxPaidQ3) +
                          getInteger(formikProps.values.advanceTaxPaidQ4)
                      );
                    }}
                    disabled={isClient}
                    maximumValue="1000000000000"
                    error={
                      formikProps.touched.advanceTaxPaidQ1 &&
                      !!formikProps.errors.advanceTaxPaidQ1
                    }
                    helperText={
                      formikProps.touched.advanceTaxPaidQ1 &&
                      formikProps.errors.advanceTaxPaidQ1
                    }
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="advanceTaxPaidQ2"
                    label="Advance tax paid in Q2"
                    onBlur={formikProps.handleBlur}
                    fullWidth
                    value={formikProps.values.advanceTaxPaidQ2}
                    onChange={(e) => {
                      formikProps.setFieldValue(
                        "advanceTaxPaidQ2",
                        e.target.value
                      );
                      formikProps.setFieldValue(
                        "advanceTaxPaidTillDate",
                        getInteger(e.target.value) +
                          getInteger(formikProps.values.advanceTaxPaidQ1) +
                          getInteger(formikProps.values.advanceTaxPaidQ3) +
                          getInteger(formikProps.values.advanceTaxPaidQ4)
                      );
                    }}
                    disabled={isClient || (!isClient && selectedQuarter < 2)}
                    maximumValue="1000000000000"
                    error={
                      formikProps.touched.advanceTaxPaidQ2 &&
                      !!formikProps.errors.advanceTaxPaidQ2
                    }
                    helperText={
                      formikProps.touched.advanceTaxPaidQ2 &&
                      formikProps.errors.advanceTaxPaidQ2
                    }
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="advanceTaxPaidQ3"
                    label="Advance tax paid in Q3"
                    onBlur={formikProps.handleBlur}
                    fullWidth
                    value={formikProps.values.advanceTaxPaidQ3}
                    onChange={(e) => {
                      formikProps.setFieldValue(
                        "advanceTaxPaidQ3",
                        e.target.value
                      );
                      formikProps.setFieldValue(
                        "advanceTaxPaidTillDate",
                        getInteger(e.target.value) +
                          getInteger(formikProps.values.advanceTaxPaidQ1) +
                          getInteger(formikProps.values.advanceTaxPaidQ2) +
                          getInteger(formikProps.values.advanceTaxPaidQ4)
                      );
                    }}
                    disabled={isClient || (!isClient && selectedQuarter < 3)}
                    maximumValue="1000000000000"
                    error={
                      formikProps.touched.advanceTaxPaidQ3 &&
                      !!formikProps.errors.advanceTaxPaidQ3
                    }
                    helperText={
                      formikProps.touched.advanceTaxPaidQ3 &&
                      formikProps.errors.advanceTaxPaidQ3
                    }
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="advanceTaxPaidQ4"
                    label="Advance tax paid in Q4"
                    onBlur={formikProps.handleBlur}
                    fullWidth
                    value={formikProps.values.advanceTaxPaidQ4}
                    onChange={(e) => {
                      formikProps.setFieldValue(
                        "advanceTaxPaidQ4",
                        e.target.value
                      );
                      formikProps.setFieldValue(
                        "advanceTaxPaidTillDate",
                        getInteger(e.target.value) +
                          getInteger(formikProps.values.advanceTaxPaidQ1) +
                          getInteger(formikProps.values.advanceTaxPaidQ3) +
                          getInteger(formikProps.values.advanceTaxPaidQ2)
                      );
                    }}
                    disabled={isClient || (!isClient && selectedQuarter < 4)}
                    maximumValue="1000000000000"
                    error={
                      formikProps.touched.advanceTaxPaidQ4 &&
                      !!formikProps.errors.advanceTaxPaidQ4
                    }
                    helperText={
                      formikProps.touched.advanceTaxPaidQ4 &&
                      formikProps.errors.advanceTaxPaidQ4
                    }
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="advanceTaxPaidTillDate"
                    label="Advance tax paid till date"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    fullWidth
                    value={formikProps.values.advanceTaxPaidTillDate}
                    disabled={true}
                    maximumValue="1000000000000"
                    error={
                      formikProps.touched.advanceTaxPaidTillDate &&
                      !!formikProps.errors.advanceTaxPaidTillDate
                    }
                    helperText={
                      formikProps.touched.advanceTaxPaidTillDate &&
                      formikProps.errors.advanceTaxPaidTillDate
                    }
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <CurrencyTextField
                    id="dueThisQuarter"
                    label="Due this quarter"
                    fullWidth
                    value={calculateDueThisQuarter(
                      formikProps.values.dueAmount,
                      formikProps.values.advanceTaxPaidTillDate
                    )}
                    disabled={true}
                    maximumValue="1000000000000"
                    minimumValue="-99999999"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    id="comment"
                    variant="outlined"
                    multiline={true}
                    label="Enter your comments/notes here"
                    name="comment"
                    rows={12}
                    value={formikProps.values.comment}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    autoComplete={false}
                    returnKeyType="next"
                    fullWidth
                    disabled={isClient}
                  />
                </Grid>
                <SizedBox height={20} width={10}></SizedBox>
                <Grid item xs={12} sm={12} md={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        isDisabled || isClient || formikProps.isSubmitting
                      }
                      onClick={formikProps.handleSubmit}
                    >
                      Save
                    </Button>
                    <SizedBox heigth={20} width={20}></SizedBox>
                    <a
                      target="_blank"
                      href={`${process.env.PUBLIC_URL}/documents/Tax_payment_procedure.pdf`}
                    >
                      Steps for Advance Tax Payment
                    </a>

                    {/* <Button
                      variant="outlined"
                      color="secondary"
                      disabled={isDisabled}
                      onClick={() => {
                        window.open(
                          "https://onlineservices.tin.egov-nsdl.com/etaxnew/tdsnontds.jsp",
                          "_blank"
                        );
                      }}
                    >
                      Pay Advance Tax
                    </Button> */}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Formik>
    </div>
  );
}
export default Summary;
