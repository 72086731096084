import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Interest from "../../components/Quarter/Interest";
import Dividends from "../../components/Quarter/Dividends";
import CG from "../../components/Quarter/CG";
import Others from "../../components/Quarter/Others";
import Summary from "../../components/Quarter/Summary";
import Rental from "../../components/Quarter/Rental";
import {
  IconButton,
  Grid,
  CircularProgress,
  TextField,
  MenuItem,
  Button,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import SizedBox from "../../components/SizedBox";
import { useToastDisplay } from "../../Common/useToastDisplay";
import { useSelectedClient } from "../../Common/useSelectedClient";
import { useQuery } from "react-query";
import API from "../../services/api";
import { useAuth } from "../../provider/auth";
import { Alert, AlertTitle } from "@material-ui/lab";
import Deduction from "../../components/Quarter/Deduction";
import HelpText from "../../Common/helpText";
import CurrencyTextField from "../../components/CurrencyTextField/CurrencyTextField";

const CURRENT_FY = process.env.REACT_APP_AY;

const useStyles = makeStyles((theme) => ({
  menu: {
    color: "#fff",
    borderRadius: 5,
    textDecoration: "none",
    // height: 50,
    margin: 10,
    textAlign: "center",
    backgroundColor: "#8e99d5",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#c5c8d9",
    },
    padding: theme.spacing(2),
  },
  input: {
    width: 1,
    height: 1,
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  },
}));

const OTHER_API_URL = process.env.REACT_APP_OTHERS_CONTROLLER;
const QTR_API_URL = process.env.REACT_APP_QTRPROFILE_CONTROLLER;
const JSON_API_URL = process.env.REACT_APP_JSON_READ_CONTROLLER;
const TAXPROFILE_API_URL = process.env.REACT_APP_TAXPROFILE_CONTROLLER;

export default function QuarterData({ activeQuarter, fy }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  // const { data, isLoading } = useQuery(
  //   ["Others_Data", id],
  //   async () => {
  //     return API.get(OTHER_API_URL + `GetOthers/${id}`)
  //       .then((res) => res.data)
  //       .catch((e) => console.log("API FAILED", e));
  //   },
  //   {
  //     retry: false,
  //     cacheTime: Infinity,
  //   }
  // );
  const client = useSelectedClient();
  const { data: multipleRecordWithImportJson, isLoading } = useQuery(
    ["HaveMultipleRecords", id],
    async () => {
      return API.get(
        QTR_API_URL +
          `IsClientHaveMultipleAdvanceTaxRecords/${client?.userProfileId}/${client?.qtrProfileId}`
      )
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
      enabled: !!client,
    }
  );

  const showToast = useToastDisplay();
  const auth = useAuth();

  //const isOtherTabVisible = data || auth.user.role !== "Client" ? true : false;
  const isOtherTabVisible = auth.user.role !== "Client" ? true : false;
  var classes = useStyles();

  const menuList = isOtherTabVisible
    ? [
        { id: 1, name: "Interest", url: `/advance-tax/quarter/${id}` },
        {
          id: 2,
          name: "Dividends",
          url: `/advance-tax/quarter/${id}/dividends`,
        },
        { id: 3, name: "Rental", url: `/advance-tax/quarter/${id}/rental` },
        { id: 4, name: "Capital Gains", url: `/advance-tax/quarter/${id}/cg` },
        { id: 5, name: "Others", url: `/advance-tax/quarter/${id}/others` },
        {
          id: 6,
          name: "Deduction",
          url: `/advance-tax/quarter/${id}/deduction`,
        },
        { id: 7, name: "Summary", url: `/advance-tax/quarter/${id}/summary` },
      ]
    : [
        { id: 1, name: "Interest", url: `/advance-tax/quarter/${id}` },
        {
          id: 2,
          name: "Dividends",
          url: `/advance-tax/quarter/${id}/dividends`,
        },
        { id: 3, name: "Rental", url: `/advance-tax/quarter/${id}/rental` },
        //{ id: 4, name: "Capital Gains", url: `/advance-tax/quarter/${id}/cg` },
        { id: 4, name: "Others", url: `/advance-tax/quarter/${id}/others` },
        {
          id: 6,
          name: "Deduction",
          url: `/advance-tax/quarter/${id}/deduction`,
        },
        { id: 7, name: "Summary", url: `/advance-tax/quarter/${id}/summary` },
      ];
  const [selectedTab, setSelectedTab] = useState(
    menuList.find((t) => t.url === location.pathname)?.id ?? 1
  );

  const [residentialStatus, setResidentialStatus] = useState(
    client?.residentialStatus
  );
  const [isChangeJob, setIsChangeJob] = useState(client?.isChangeJob ?? "0");

  useEffect(() => {
    if (client) {
      setResidentialStatus(client.residentialStatus);
      setIsChangeJob(client?.isChangeJob ?? "0");
    }
  }, [client]);

  const isImportJsonVisible = true;
  const [isFileLoading, setIsFileLoading] = useState(false);

  const selectedQuarter = parseInt(client?.qtrType.replace(/[^0-9]/g, "") || 0);
  
  const [seniorCitizen, setSeniorCitizen] = useState(
    client?.seniorCitizen || false
  );

  // const [expectedSalary, setExpectedSalary] = useState(
  //   client?.expectedTaxableSalary || "0"
  // );

  const handleFileChange = (event) => {
    const { target } = event;
    const { files } = target;
    setIsFileLoading(true);
    if (files && files[0]) {
      var reader = new FileReader();
      reader.onload = (event) => {
        console.log("event", event.target);
      };

      reader.readAsDataURL(files[0]);

      const formData = new FormData();
      formData.append("file", files[0]);

      API.post(JSON_API_URL + `ImportJsonForAdvanceTax/${id}`, formData)
        .then((res) => {
          if (res.data.isSuccess) {
            showToast({
              message: "Json imported successfully",
              severity: "success",
            });
          } else {
            showToast({
              message: res.data.msg || "Error occured while importing json",
              severity: "error",
            });
          }
          setIsFileLoading(false);
          window.location.reload();
          //refetch();
        })
        .catch((err) => {
          showToast({
            message: "Error occured while importing json",
            severity: "error",
          });
          setIsFileLoading(false);
        });
    }
  };
  // const handleExpectedSalaryUpdate = () => {
  //   const updatedData = {
  //     NewTaxableSalary: expectedSalary,
  //     UserProfileId: client.userProfileId,
  //     TaxProfileId: client.taxProfileId,
  //   };

  //   API.post(QTR_API_URL + "UpdateTaxableSalary", updatedData)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         showToast({
  //           message: "Expected Total Income updated successfully!",
  //           severity: "success",
  //         });
  //       } else {
  //         showToast({
  //           message:
  //             "Failed to update Expected Total Income.",
  //           severity: "error",
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       showToast({
  //         message: "Failed to update Expected Total Income.",
  //         severity: "error",
  //       });
  //     });
  // };

  if (isLoading)
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        size={60}
      />
    );

  return (
    <Grid container spacing={2}>
      <Grid item md={1}></Grid>
      <Grid item xs={11} sm={11} md={10}>
        <div style={{ marginTop: 16 }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <IconButton
              aria-haspopup="true"
              color="inherit"
              aria-controls="profile-menu"
              onClick={(e) => navigate("/advance-tax")}
            >
              <ArrowBack classes={{ root: classes.headerIcon }} />
            </IconButton>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {auth.user.role !== "Client" && client && (
                <h2 style={{ textAlign: "center" }}>
                  {client.firstName} {client.lastName} ({client.pan})
                </h2>
              )}
              {selectedQuarter > 0 && (
                <h2 style={{ textAlign: "center" }}>
                  Q{selectedQuarter} -{" "}
                  {selectedQuarter === 1
                    ? "June"
                    : selectedQuarter === 2
                    ? "Sept"
                    : selectedQuarter === 3
                    ? "Dec"
                    : "Mar"}{" "}
                  15 - FY {fy}
                </h2>
              )}
              {auth.user.role !== "Client" && client && (
                <CurrencyTextField
                  id="taxableSalary"
                  label="Previous Year Total Income"
                  value={client.expectedTaxableSalary}
                  style={{ marginLeft: 30, marginTop: 10 }}
                  disabled={true}
                />
              )}
             

              {auth.user.role !== "Client" && client && (
                <CurrencyTextField
                  id="expectedSalary"
                  label="Expected Total Income"
                  value={client.taxableSalary}
//                  value={expectedSalary}
                  style={{ marginLeft: 30, marginTop: 10 }}
                  disabled={true}
  //                onChange={(e, value) => setExpectedSalary(value)}
                />
              )}
              {/* {auth.user.role !== "Client" && client && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleExpectedSalaryUpdate}
                  style={{
                    height: "35%",
                    width: "50px",
                    marginTop: 12,
                    marginLeft: 10,
                  }}
                >
                  <p style={{ fontSize: 10 }}>Update</p>
                </Button>
              )} */}
              {auth.user.role !== "Client" && client && (
                <TextField
                  id="taxRate"
                  type="text"
                  label="Tax Rate"
                  style={{ marginLeft: 10, width: "140px", marginTop: 10 }}
                  variant="outlined"
                  disabled={true}
                  size="small"
                  value={client.taxRate}
                ></TextField>
              )}
              {auth.user.role !== "Client" && client && (
                <TextField
                  id="residentialStatus"
                  select
                  label="Residential Status"
                  style={{ marginLeft: 10, width: 220, marginTop: 10 }}
                  variant="outlined"
                  size="small"
                  disabled={true}
                  value={residentialStatus}
                  onChange={(e) => {
                    setResidentialStatus(e.target.value);
                    API.post(TAXPROFILE_API_URL + "UpdateResidentialStatus", {
                      residentialStatus: e.target.value,
                      fy: fy,
                      userProfileGuid: client.userProfileId,
                      taxProfileId: client.taxProfileId,
                    }).then((response) => {
                      showToast({
                        message: "Residential status submitted successfully!",
                        severity: "success",
                      });
                    });
                  }}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value="1">
                    <em>Ordinarily Resident</em>
                  </MenuItem>
                  <MenuItem value="2">
                    <em>Not ordinarily resident</em>
                  </MenuItem>
                  <MenuItem value="3">
                    <em>Non-resident</em>
                  </MenuItem>
                </TextField>
              )}

              {auth.user.role !== "Client" && client && (
                <>
                  {/* ... other code */}
                  <TextField
                    id="seniorCitizen"
                    select
                    label="Senior Citizen"
                    style={{ marginLeft: 10, width: 220, marginTop: 10 }}
                    variant="outlined"
                    size="small"
                    disabled={true}
                    value={seniorCitizen}
                    onChange={(e) => {
                      const newValue = e.target.value === "true";
                      setSeniorCitizen(newValue);
                      API.post(
                        TAXPROFILE_API_URL + "UpdateSeniorCitizenStatus",
                        {
                          isSeniorCitizen: newValue,
                          userProfileGuid: client.userProfileId,
                          taxProfileId: client.taxProfileId,
                        }
                      )
                        .then((response) => {
                          showToast({
                            message:
                              "Senior citizen status updated successfully!",
                            severity: "success",
                          });
                        })
                        .catch((error) => {
                          showToast({
                            message: "Failed to update senior citizen status.",
                            severity: "error",
                          });
                        });
                    }}
                  >
                    <MenuItem value="false">
                      <em>No</em>
                    </MenuItem>
                    <MenuItem value="true">
                      <em>Yes</em>
                    </MenuItem>
                  </TextField>
                </>
              )}
            </div>
          </div>
          {multipleRecordWithImportJson &&
            multipleRecordWithImportJson.isMultipleRecords === true &&
            fy === CURRENT_FY && (
              <Alert severity="info">
                <AlertTitle>
                  We have pre-filled income details from last year for your
                  reference. Please update the amounts for FY {CURRENT_FY}.
                </AlertTitle>
              </Alert>
            )}
          {fy !== CURRENT_FY && (
            <Alert severity="warning">
              <AlertTitle>
                You are reviewing prior year's details. Please select FY{" "}
                {CURRENT_FY} to provide the information for this year's advance
                tax.
              </AlertTitle>
            </Alert>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              {menuList &&
                menuList.map((t, index) => {
                  return (
                    <div
                      key={t.id}
                      className={classes.menu}
                      style={
                        selectedTab === t.id
                          ? { backgroundColor: "#edc274" }
                          : null
                      }
                      onClick={() => {
                        setSelectedTab(t.id);
                        navigate(t.url);
                      }}
                    >
                      {t.name}
                    </div>
                  );
                })}
            </div>
            {isImportJsonVisible && auth.user.role !== "Client" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                {auth.user.role !== "Client" && client && (
                  <TextField
                    id="isChangeJob"
                    select
                    label="Change of Job"
                    style={{ marginLeft: 30, width: 200, marginTop: 10 }}
                    variant="outlined"
                    size="small"
                    value={isChangeJob}
                    onChange={(e) => {
                      setIsChangeJob(e.target.value);
                      API.post(TAXPROFILE_API_URL + "UpdateChangeOfJob", {
                        isChangeJob: e.target.value === "1" ? true : false,
                        userProfileGuid: client.userProfileId,
                        taxProfileId: client.taxProfileId,
                      }).then((response) => {
                        showToast({
                          message: "Change of job submitted successfully!",
                          severity: "success",
                        });
                      });
                    }}
                  >
                    <MenuItem value="0">
                      <em>No</em>
                    </MenuItem>
                    <MenuItem value="1">
                      <em>Yes</em>
                    </MenuItem>
                  </TextField>
                )}
                {/* {activeQuarter === 2 && ( */}
                  <div style={{ marginLeft: 5 }}>
                    <input
                      id={`importJson`}
                      type="file"
                      accept={".json"}
                      onChange={handleFileChange}
                      className={classes.input}
                    />

                    <div>
                      <div variant="contained">
                        <label
                          htmlFor={`importJson`}
                          style={{
                            padding: 10,
                            backgroundColor: "#334f64",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "8px",
                            fontSize: 12,
                            width: 150,
                            alignSelf: "center",
                          }}
                        >
                          Import Json
                        </label>
                      </div>
                      {multipleRecordWithImportJson &&
                        multipleRecordWithImportJson.isJsonImported ===
                          true && <HelpText>Already imported</HelpText>}

                      {isFileLoading && <span>Uploading...</span>}
                    </div>
                  </div>
               {/* )} */}
              </div>
            )}
          </div>
        </div>
        <SizedBox height={20} width={10}></SizedBox>
        <div style={{ margin: 10 }}>
          <Routes>
            <Route
              path="/"
              element={
                <Interest
                  {...{ activeQuarter, setSelectedTab, client, showToast }}
                />
              }
            />
            <Route
              path="/dividends"
              element={
                <Dividends
                  {...{ activeQuarter, setSelectedTab, client, showToast }}
                />
              }
            />
            <Route
              path="/rental"
              element={
                <Rental
                  {...{
                    activeQuarter,
                    setSelectedTab,
                    client,
                    showToast,
                    isOtherTabVisible,
                  }}
                />
              }
            />
            {isOtherTabVisible === true && (
              <Route
                path="/cg"
                element={
                  <CG
                    {...{
                      activeQuarter,
                      setSelectedTab,
                      client,
                      showToast,
                      isOtherTabVisible,
                      role: auth.user.role,
                    }}
                  />
                }
              />
            )}
            <Route
              path="/others"
              element={
                <Others
                  {...{
                    setSelectedTab,
                    showToast,
                    role: auth.user.role,
                  }}
                />
              }
            />

            <Route
              path="/deduction"
              element={
                <Deduction
                  {...{
                    setSelectedTab,
                    client,
                    showToast,
                    isOtherTabVisible,
                  }}
                />
              }
            />
            <Route
              path="/summary"
              element={<Summary {...{ role: auth.user.role, client }} />}
            />
          </Routes>
        </div>
      </Grid>
      <Grid item md={1}></Grid>
    </Grid>
  );
}
