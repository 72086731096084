import React, { useState } from "react";
import { useQuery } from "react-query";
import API from "../../services/api";
import SizedBox from "../SizedBox";
import useDebounce from "../../Common/useDebounce";
import { useNavigate } from "react-router-dom";
import { useUpdateSelectedClient } from "../../Common/useSelectedClient";
import {
  EditOutlined,
  AssignmentOutlined,
  AddCircleOutline,
  UpdateOutlined,
  ViewHeadline,
} from "@material-ui/icons";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Grid,
  TextField,
  CircularProgress,
  IconButton,
  Link,
  Modal,
  MenuItem,
} from "@material-ui/core";
import ReAssignTaxAdvisor from "../Common/ReAssignTaxAdvisor";
import AddFamilyProfile from "../Profile/AddFamilyProfile";
import ServiceType from "../../Common/ServiceType";
import ProfileStatus from "../../Common/ProfileStatus";
import ChangeStatus from "../Common/ChangeStatus";
import { FilterList } from "@material-ui/icons";
import ChangeItdPassword from "../Common/ChangeItdPassword";

const API_URL = process.env.REACT_APP_ADVISOR_CONTROLLER;
const TAX_API_URL = process.env.REACT_APP_TAXPROFILE_CONTROLLER;

export default function LoadTaxFilingClientForAdvisor({ fy, role }) {
  const [search, setSearch] = useState({ status: 0, text: "" });
  const debounceSearch = useDebounce(search.text, 1000);
  let navigate = useNavigate();
  const setClient = useUpdateSelectedClient();
  const [open, setOpen] = useState(false);
  const [openAddMember, setAddMemberOpen] = useState(false);
  const [advisorToClient, setAdvisorToClient] = useState();
  const [openStatusChnage, setOpenStatusChnage] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [openItdPassword, setOpenItdPassword] = useState(false);

  const [selectedWPAdvisorName, setSelectedWPAdvisorName] = useState("");
  const [selectedTaxAdvisorName, setSelectedTaxAdvisorName] = useState("");
  const { data, isLoading, refetch } = useQuery(
    ["ALL_CLIENT_PROFILE_FOR_ADVISOR", debounceSearch, search.status],
    async () => {
      return API.post(API_URL + "GetAssignedTaxFilingClients", {
        searchText: debounceSearch,
        statusId: search.status,
      })
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    }
  );

  ///const allUserList = data ? { ...data, isStatusChange: false } : [];

  const { data: roleAccess } = useQuery(
    ["GET_ADVISOR_TYPE_WITH_ACCESS"],
    async () => {
      return API.get(API_URL + `GetAdvisorTypeWithAccess`)
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );

  function redirectToFiling(profileId) {
    API.get(TAX_API_URL + `CheckAndGetTaxProfile/${profileId}?fy=${fy}`)
      .then((res) => {
        setClient({
          ...res.data,
        });
        if (
          res.data &&
          ProfileStatus[res.data.status].id >=
            ProfileStatus.TaxSummaryPrepared.id
        ) {
          navigate(`/income/filing/${res.data.taxProfileId}/summary`);
        } else {
          navigate(`/income/filing/${res.data.taxProfileId}`);
        }
      })
      .catch((err) => {
        console.log("Error occured while fetching quarter");
      });
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        size={60}
      />
    );
  }
  return (
    <Grid container style={{ padding: "10px" }}>
      <Grid item md={2}></Grid>
      <Grid item xs={12} sm={12} md={8}>
        <SizedBox height={10} width={10}></SizedBox>
        <div>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Grid item xs={4} style={{ margin: 10 }}>
              <h3>Current FY: {fy}</h3>
            </Grid>
            <Grid item xs={4} style={{ margin: 10 }}>
              <TextField
                name="ddlStatus"
                select
                label="Select Status"
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                value={search.status}
                onChange={(e) =>
                  setSearch({ ...{ search, status: e.target.value } })
                }
              >
                {[
                  ProfileStatus.Unknown,
                  ProfileStatus.Pipeline,
                  ProfileStatus.Initiated,
                  ProfileStatus.Assigned,
                  ProfileStatus.InProcess,
                  ProfileStatus.PendingFromClient,
                  ProfileStatus.PendingForInternalReview,
                  ProfileStatus.TaxSummaryPrepared,
                  ProfileStatus.TaxSummaryPayable,
                  ProfileStatus.TaxSummaryRejected,
                  ProfileStatus.TaxSummaryApproved,
                  ProfileStatus.ReadyToEfile,
                  ProfileStatus.Efile,
                  ProfileStatus.EVerified,
                ].map((t) => {
                  return (
                    <MenuItem key={t.id} value={t.id}>
                      <em>{t.name}</em>
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid
              item
              xs={role === "Admin" ? 4 : 3}
              style={{ margin: 10, marginRight: 0 }}
            >
              <TextField
                id="search2"
                name="search2"
                label="Search Client"
                variant="outlined"
                size="small"
                autoComplete="off"
                type="text"
                style={{ width: "100%" }}
                value={search.text}
                onChange={(e) =>
                  setSearch({ ...{ search, text: e.target.value } })
                }
              />
            </Grid>
            <IconButton
              aria-label="Filter"
              color="primary"
              onClick={() => setShowFilter(!showFilter)}
              style={{ marginBottom: 10 }}
            >
              <FilterList />
            </IconButton>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Grid
              item
              xs={4}
              style={{ margin: 10, width: "30%", marginBottom: 0 }}
            >
              <p style={{ fontSize: 1 }}>:</p>
            </Grid>
            {showFilter && (
              <>
                <Grid item xs={4} style={{ margin: 10 }}>
                  <TextField
                    name="ddlWPAdvisor"
                    select
                    label="Select RM Advisor"
                    variant="outlined"
                    size="small"
                    style={{ width: "100%" }}
                    value={selectedWPAdvisorName}
                    onChange={(e) => setSelectedWPAdvisorName(e.target.value)}
                  >
                    <MenuItem value="">All</MenuItem>
                    {data &&
                      [
                        ...new Set(
                          data.map((advisor) => advisor.wpAdvisorName)
                        ),
                      ].map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item xs={4} style={{ margin: 10, marginRight: "5.5%" }}>
                  <TextField
                    name="ddlTaxAdvisor"
                    select
                    label="Select Tax Advisor"
                    variant="outlined"
                    size="small"
                    style={{ width: "100%" }}
                    value={selectedTaxAdvisorName}
                    onChange={(e) => setSelectedTaxAdvisorName(e.target.value)}
                  >
                    <MenuItem value="">All</MenuItem>
                    {data &&
                      [
                        ...new Set(data.map((advisor) => advisor.advisorName)),
                      ].map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}{" "}
                  </TextField>
                </Grid>
              </>
            )}
          </div>
        </div>

        <div className="row" style={{ marginTop: 16, border: 1 }}>
          <Grid container>
            <SizedBox height={10} width={10}></SizedBox>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Status</b>
                  </TableCell>
                  <TableCell>
                    <b>RM Advisor</b>
                  </TableCell>
                  <TableCell>
                    <b>Tax Advisor</b>
                  </TableCell>
                  <TableCell>
                    <b>View Return</b>
                  </TableCell>
                  <TableCell>
                    <b>View Documents</b>
                  </TableCell>
                  {roleAccess && roleAccess.allowReAssign && (
                    <TableCell>
                      <b>Assign</b>
                    </TableCell>
                  )}
                  {roleAccess && roleAccess.allowAddFamilyMember && (
                    <TableCell>
                      <b>Add Family Member</b>
                    </TableCell>
                  )}
                  <TableCell>
                    <b>Change Status</b>
                  </TableCell>
                  <TableCell>
                    <b>ITD Password</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.length > 0 &&
                  data
                    .filter(
                      (client) =>
                        (selectedWPAdvisorName
                          ? client.wpAdvisorName === selectedWPAdvisorName
                          : true) &&
                        (selectedTaxAdvisorName
                          ? client.advisorName === selectedTaxAdvisorName
                          : true)
                    )
                    .map((t, index) => {
                      return (
                        <TableRow key={index}>
                          {/* for key warning in console we can do this - key={t.id} */}
                          <TableCell>
                            <Link
                              onClick={(e) => {
                                navigate(
                                  `/incomeprofile/${t.userProfileId}?type=income`
                                );
                              }}
                            >
                              {t.firstName} {t.lastName} ({t.relationship})
                            </Link>
                          </TableCell>
                          <TableCell>
                            {ProfileStatus[t?.status].name ?? ""}
                          </TableCell>
                          <TableCell>{t?.wpAdvisorName ?? ""}</TableCell>
                          <TableCell>{t?.advisorName ?? ""}</TableCell>
                          <TableCell>
                            <IconButton
                              aria-haspopup="true"
                              color="inherit"
                              aria-controls="profile-menu"
                              style={{ padding: 6 }}
                              onClick={() => {
                                redirectToFiling(t.userProfileId);
                              }}
                            >
                              <EditOutlined />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              aria-haspopup="true"
                              color="inherit"
                              aria-controls="profile-menu"
                              style={{ padding: 6 }}
                              onClick={() => {
                                setClient({
                                  ...t,
                                });
                                navigate(
                                  `/income/documents/${t.userProfileId}`
                                );
                              }}
                            >
                              <ViewHeadline />
                            </IconButton>
                          </TableCell>
                          {roleAccess && roleAccess.allowReAssign && (
                            <TableCell>
                              <IconButton
                                aria-haspopup="true"
                                color="inherit"
                                aria-controls="profile-menu"
                                style={{ padding: 6 }}
                                onClick={() => {
                                  setAdvisorToClient(t);
                                  handleOpen();
                                }}
                              >
                                <AssignmentOutlined />
                              </IconButton>
                            </TableCell>
                          )}
                          {roleAccess && roleAccess.allowAddFamilyMember && (
                            <TableCell>
                              <IconButton
                                aria-haspopup="true"
                                color="inherit"
                                aria-controls="profile-menu"
                                style={{ padding: 6 }}
                                onClick={() => {
                                  setAdvisorToClient(t);
                                  setAddMemberOpen(true);
                                }}
                              >
                                <AddCircleOutline />
                              </IconButton>
                            </TableCell>
                          )}
                          <TableCell>
                            <IconButton
                              aria-haspopup="true"
                              color="inherit"
                              aria-controls="profile-menu"
                              style={{ padding: 6 }}
                              onClick={() => {
                                setAdvisorToClient(t);
                                setOpenStatusChnage(true);
                              }}
                            >
                              <UpdateOutlined />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              aria-haspopup="true"
                              color="inherit"
                              aria-controls="profile-menu"
                              style={{ padding: 6 }}
                              onClick={() => {
                                setAdvisorToClient(t);
                                setOpenItdPassword(true);
                              }}
                            >
                              <EditOutlined />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </Grid>

          {data && data.length === 0 && (
            <div>
              <SizedBox height={40} width={20}></SizedBox>
              <h2>No Data Found</h2>
            </div>
          )}
        </div>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={open}
          onClose={handleClose}
        >
          <ReAssignTaxAdvisor
            {...{
              row: advisorToClient,
              setOpen,
              advisorType: roleAccess?.advisorType,
              isAllowTpReassign: roleAccess?.isAllowTpReassign ?? false,
              isAllowWpReassign: roleAccess?.isAllowWpReassign ?? false,
              refetch,
            }}
          ></ReAssignTaxAdvisor>
        </Modal>

        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={openAddMember}
          onClose={() => {
            setAddMemberOpen(false);
          }}
        >
          <AddFamilyProfile
            {...{
              row: advisorToClient,
              service: ServiceType.INCOMETAX,
              setAddMemberOpen,
              refetch,
            }}
          ></AddFamilyProfile>
        </Modal>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={openStatusChnage}
          onClose={() => {
            setOpenStatusChnage(false);
          }}
        >
          <ChangeStatus
            {...{
              row: advisorToClient,
              setOpen: setOpenStatusChnage,
              refetch,
            }}
          ></ChangeStatus>
        </Modal>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={openItdPassword}
          onClose={() => {
            setOpenItdPassword(false);
          }}
        >
          <ChangeItdPassword
            {...{
              row: advisorToClient,
              setOpen: setOpenItdPassword,
              refetch,
            }}
          ></ChangeItdPassword>
        </Modal>
      </Grid>
      <Grid item md={1}></Grid>
    </Grid>
  );
}
